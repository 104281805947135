#login-button {
	color: chocolate;
	border-color: chocolate;
}

#login-button:hover {
	color: white;
	background-color: chocolate;
}

.navbar-brand {
	padding-left: 25px;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
	.parent-navbar {
		width: 100%;
		margin: 0;
		padding: 0;
		display: block;
	}
}
