.no-padding {
	padding-left: 0;
	padding-right: 0;
}

.centered {
	position: absolute;
	top: 25%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: whitesmoke;
	font-family: 'Caveat', cursive;
}
