.showcase-parent {
	width: 440;
	height: 'auto';
}

@media screen and (max-width: 600px) and (orientation: portrait) {
	.showcase-parent {
		width: 10%;
	}

	.showcase-iframe {
		margin: auto;
		display: flex;
	}
}
