@media screen and (max-width: 600px) and (orientation: portrait) {
	.welcome-item {
		text-align: center;
	}

	.welcome-img {
		height: 5rem;
		width: 5rem;
	}

	.welcome-buttons {
		display: flex;
		justify-content: center;
	}

	.button-b {
		width: 95%;
	}
}
