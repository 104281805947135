body {
  background-color: #f9f9f9;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.button-b {
  color: chocolate;
  border-color: chocolate;
}

.button-b:hover {
  color: white;
  background-color: chocolate;
}
