.no-padding {
	padding-left: 0;
	padding-right: 0;
}

.centered.display-2 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: whitesmoke;
	font-family: 'Caveat', cursive;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
	.centered.display-2 {
		position: relative;
		top: -62%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		font-size: 32px;
		text-align: center;
		height: 1rem;
	}

	.img-responsive {
		height: 20rem;
		width: 100vw;
		position: relative;
	}

	.home-schedule {
		font-size: 20px;
	}
}
