.contact-form {
	width: 50%;
	margin: 40px auto;
	border: 1px solid lightgray;
	padding: 30px;
	border-radius: 6px;
	box-shadow: -8px 8px 16px 4px rgba(0, 0, 0, 0.1);
	background: #f9f9f9;
}

input,
textarea {
	padding: 8px;
	margin-top: 1% !important;
}

button.button-b {
	margin-top: 1%;
}

button#dropdown-basic {
	margin-top: 1% !important;
}

.contact-info {
	text-align: center;
}

.tell-us {
	margin-left: 15px;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
	.contact-container {
		max-width: 100vw;
	}
	.contact-form {
		width: 95%;
		display: flex;
		flex-direction: column;
	}
}
