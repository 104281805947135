.sermontron-img {
	width: 100vw;
	height: 400px;
}

.centered.display-2 {
	position: relative;
	top: -62%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 65px;
	text-align: center;
	height: 1rem;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
	.centered.display-2 {
		position: relative;
		top: -62%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		font-size: 32px;
		text-align: center;
		height: 1rem;
	}

	.img-responsive {
		height: 15rem;
		width: 100vw;
		position: relative;
	}

	.home-schedule {
		font-size: 20px;
	}
}
