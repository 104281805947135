.church-card {
	width: 18rem;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
	.church-card {
		width: 95vw;
		margin-bottom: 5px;
	}

	.church-card-row {
		align-items: center;
		justify-content: center;
	}
	.church-card-title {
		text-align: center;
	}
}
